import * as React from 'react'
import { Link } from 'gatsby'

const MembersPage = () => {
  return (
    <main>
      <title>Members Page</title>
      <h1>Welcome to Members</h1>
      <Link to="/">Home</Link>
    </main>
  )
}

export async function getServerData(context) {
  return {
    status: 200, // The HTTP status code that should be returned
    props: {}, // Will be passed to the page component as "serverData" prop
    headers: { "X-Members" : "yep" }, // HTTP response headers for this page
  }
}

export default MembersPage